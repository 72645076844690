<template>
    <div class="d-flex all-after-header-box">
        <!-- Левое меню модуля -->
        <div 
            :class="{'drawer-left-wrap' : true, 'dlw-large' : (!drawerLeftMenuMini), 'drawer-menu-hover': drawerLeftMenuHover }"
            @mouseenter="LeftMenuMouseenter()"
            @mouseleave="LeftMenuMouseleave()"
        >
            <v-navigation-drawer
                absolute
                permanent
                class="drawer-menu"
                v-model="drawerLeftMenu"
            >

                <template v-if="allowCreateReportQuery">
                    <v-divider class="mr-2 ml-2"></v-divider>

                    <v-list
                        nav
                        dense
                        class="dm-actions-list"
                    >
                        <v-list-item-group
                            color="#009c95"
                        >
                            <v-list-item 
                                link
                                class="v-item--active v-list-item--active drawer-menu-list-bigbtn"
                                @click="onCreateClicked"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-plus-box</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ $t("Добавить") }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </template>

                <v-divider class="mr-2 ml-2"></v-divider>

                <v-list
                    nav
                    dense
                    shaped
                >
                    <v-list-item-group>
                        <v-list-item
                            link
                            :to="{ name: 'AllMyReport' }"
                        >
                            <v-list-item-icon>
                                <v-icon>far fa-inbox</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("Все_запросы") }}</v-list-item-title>
                            <div v-if="!drawerLeftMenuMini" class="dm-counter">{{ AllMyRportsCount }}</div>
                        </v-list-item>

                        <v-list-item
                            link
                            :to="{ name: 'MyCreated' }"
                        >
                            <v-list-item-icon>
                                <v-icon>far fa-inbox-out</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("Поручено_мной") }}</v-list-item-title>
                            <div v-if="!drawerLeftMenuMini" class="dm-counter">{{ MyCreatedCount }}</div>
                        </v-list-item>

                        <v-list-item
                            link
                            :to="{ name: 'MyExecution' }"
                        >
                            <v-list-item-icon>
                                <v-icon>far fa-inbox-in</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("Поручено_мне") }}</v-list-item-title>
                            <div v-if="!drawerLeftMenuMini" class="dm-counter">{{ MyExecutionCount }}</div>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>

            </v-navigation-drawer>
        </div>

        <div class="content-box">

            <router-view :key="$route.fullPath" />
            
        </div>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: "ReportQueries",
    components: {
    },
    computed: {
        ...mapGetters({ drawerLeftMenuMini: 'isDrawerLeftMenuMinimized' }),
        ...mapGetters('global/actionsource', ['getDataSourceType', 'isDataSourceNewRecord'] ),
        ...mapGetters('reportsgeneration', {
            AllMyRportsCount : 'getAllMyRportsCount', 
            MyCreatedCount: 'getMyCreatedCount',
            MyExecutionCount : 'getMyExecutionCount', 
        }),
        allowCreateReportQuery(){
            return this.$store.getters['global/auth/getUserInfo'].Permissions.includes("CreateReportQuery");
        },
    },
    data: () => ({
        drawerLeftMenu: true,
        drawerLeftMenuHover: false,
    }),    
    methods: {
        ...mapActions('global/actionsource', ['loadDataSource']),
        ...mapActions('reportsgeneration', ['updateCounter']),
        LeftMenuMouseenter() {
            if (this.drawerLeftMenuMini)
                this.drawerLeftMenuHover = true;
        },
        LeftMenuMouseleave() {
            this.drawerLeftMenuHover = false;  
        },
        async onCreateClicked() {
            if (!(this.$route.name == "ReportQuery" && this.getDataSourceType == "ReportQuery" && this.isDataSourceNewRecord ))
                await this.loadDataSource({ createType: "Documents.ReportGeneration.Query", navigate: true, common: true });
        },
    },
    async mounted() {
        await this.updateCounter();
    }
}
</script>